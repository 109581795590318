<template>
  <div class="updatePassword" :style="'height: ' + windowHeight + 'px;'">
    <!--        <div style=" width: 92%;height: 1.2rem;"></div>-->
<!--    <rxNavBar title="房源相关" androidOrIOSFlag="true" style="background-color: red" backColor="true" @click-left="_onBackTap"></rxNavBar>-->
<!--    <rxNavBar title="房源相关"></rxNavBar>-->
<!--    <van-nav-bar fixed @click-left="leftReturn" title="房源相关" left-arrow></van-nav-bar>-->
    <van-nav-bar title="房源相关" left-arrow @click-left="leftReturn" fixed z-index="99" placeholder />
    <div class="img_group">
      <img :src="headImg" class="oneImg">
      <div class="setPsd">房源相关</div>
    </div>

    <div class="appointmentDetail">
      <div class="relevvant-detail relevvant-detail-border" @click="skipRenterContract" v-if="renterContractFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantRenterImg" class="detail-img"></div>
        <span class="houseRelevantText">租客合同</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="skipOwnerContract" v-if="ownerContractFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantOwnerImg" class="detail-img"></div>
        <span class="houseRelevantText">业主合同</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border" @click="skipTakeLook" v-if="takeLookFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantTakeLockImg"  class="detail-img"></div>
        <span class="houseRelevantText">带看记录</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="editRoomFeature" v-if="editRoomFeatureFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantTakeLockImg"  class="detail-img"></div>
        <span class="houseRelevantText">房源特色</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="changeQuotation" v-if="changeOfferPriceFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantOfferImg"  class="detail-img"></div>
        <span class="houseRelevantText">修改报价</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="changeCostPrice" v-if="specialoffer">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantOfferImg"  class="detail-img"></div>
        <span class="houseRelevantText">负成本报价</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="uploadImg" v-if="uploadImgFlag">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantuploadImg"  class="detail-img"></div>
        <span class="houseRelevantText">上传图片</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="houseChangeStatus" v-if="changeSubletFlag && subType == 8">
        <div style="height:1.2rem;float: left"><img :src="houseRelevantCutImg"  class="detail-img"></div>
        <span class="houseRelevantText" v-if="changeStatus == 1">标记非转租</span>
        <span class="houseRelevantText" v-else>标记转租</span>
      </div>
        <van-popup v-model="isChangeStatusShow" class="van-popup--bottom" position="bottom" :style="{ height: '30%' }">
          <img class="cancelOrdersImg" src="../../assets/images/colon.png">
          <div class="cnacelOrdersText">确认</div>
          <div class="confirmCancelOrdersText" v-if="changeStatus == 1">标记非转租？</div>
          <div class="confirmCancelOrdersText" v-else>标记转租？</div>
          <van-button @click="saveChangeStatus" class="saveButton_Enable" style="margin-top: 50px">确认</van-button>
        </van-popup>
      <div class="relevvant-detail relevvant-detail-border"  @click="roomConfiguration" v-if="specialoffer">
        <div style="height:1.2rem;float: left"><img :src="houseup01png"  class="detail-img"></div>
        <span class="houseRelevantText">房间配置</span>
      </div>
      <div class="relevvant-detail relevvant-detail-border"  @click="applyRepair" v-if="requestRepair">
        <div style="height:1.2rem;float: left"><img :src="houseup02png"  class="detail-img"></div>
        <span class="houseRelevantText">申请维修</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NavBar,
  NumberKeyboard,
  Toast,
  Calendar,
  DatetimePicker,
  Button,
  Popup
} from 'vant'
import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../libs/rongxunUtil";
import {
  bannerLinkUser,
  changeHouseType,
  roomDetail,
  addUserAppointmentNew, userGetPassword, houseRelevantAuthList, getStaffFuntionModelList
} from "../../getData/getData";

//调用android关闭页面方法*******begin*********
function backToAndroid(){
  window.himi.closeWindow();
}
// import {} from "../../getData/getData";
// import {responseUtil} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
export default {
  name: 'houseRelevant',
  components: {
    [NavBar.name]: NavBar,
    [NumberKeyboard.name]: NumberKeyboard,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Calendar.name]: Calendar,
    [DatetimePicker.name]: DatetimePicker,
    rxNavBar,
  },
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
      isChangeStatusShow:false,
      renterContractFlag:false,
      ownerContractFlag:false,
      takeLookFlag:false,
      changeOfferPriceFlag:false,
      editRoomFeatureFlag:false,
      uploadImgFlag:false,
      changeSubletFlag:false,
      specialoffer:false,
      requestRepair:false,
      setHouse_id:'',
      changeStatus:'',
      contract_id:'',
      subType:'',
      roomId: '',
      month: "",
      date: new Date(),
      dateTime:true,
      show: false,
      showaa: false,
      dateformat: "",
      errorImg: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',

      timeImg: require("../../assets/images/timepick.png"),
      headImg: require("../../assets/images/head-quotation-white.png"),
      houseRelevantOwnerImg: require("../../assets/images/house-relevant-owner.png"),  // 业主
      houseRelevantuploadImg: require("../../assets/images/house-relevant-upload.png"),  // 上传图片
      houseRelevantTakeLockImg: require("../../assets/images/house-relevant-take-lock.png"),  // 带看
      houseRelevantOfferImg: require("../../assets/images/house-relevant-offer.png"),  // 带看
      houseRelevantRenterImg: require("../../assets/images/house-relevant-renter.png"),  // 租客
      houseRelevantCutImg: require("../../assets/images/house-relevant-cut.png"),  // 切换转租状态
      houseup01png:require("../../assets/images/house-relevant-upload.png"),
      houseup02png:require("../../assets/images/house-relevant-repaire.png"),

      appointmentData: {
        photo: require("../../assets/images/1.png"),
        address: "天通苑南 天通苑西区 朝南主…",
        district: "沙河口",
        area: "123m²",
        floor: "6层",
        money: "¥ 809"
      }
    }
  },
  mounted() {
    this.roomId = this.$route.query.room_id
    this.setHouse_id = this.$route.query.setHouse_id
    this.contract_id = this.$route.query.contractId
    this.subType = this.$route.query.roomType
    this.changeStatus = this.$route.query.changeStatus != undefined ? this.$route.query.changeStatus : 0
    this.houseRelevantAuthList()
    // this.initData()
  },
  methods: {
    _onBackTap(){
      this.$router.go(-1)
    },
    houseRelevantAuthList(){
      let that = this
      let initData = {}
      initData.staff_id = getStaffId()
      initData.menuName = 'houseRelevant_index'
      getStaffFuntionModelList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data.data)
          let list = response.data.data.data;
          that.renterContractFlag = list.indexOf("renterContract") != -1
          that.ownerContractFlag = list.indexOf("ownerContract") != -1
          that.takeLookFlag = list.indexOf("takeLook") != -1
          that.changeOfferPriceFlag = list.indexOf("changeOfferPrice") != -1
          that.editRoomFeatureFlag = list.indexOf("editRoomFeature") != -1
          that.uploadImgFlag = list.indexOf("uploadImg") != -1
          that.changeSubletFlag = list.indexOf("changeSublet") != -1
          that.specialoffer = list.indexOf("specialoffer") != -1
          that.requestRepair = list.indexOf("requestRepair") != -1
        })
      })
    },
    //跳转租客合同
    skipRenterContract(){
      this.$router.push({
        name: 'contractList',
        query: {
          fromType:'0',
          type: 1,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    //跳转业主合同
    skipOwnerContract(){
      this.$router.push({
        name: 'contractList',
        query: {
          fromType:'0',
          type: 2,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
          currentLabel: '0',  // 全部
        }
      })
    },
    //跳转带看记录
    skipTakeLook(){
      this.$router.push({
        name: 'takeALook',
        query: {
          // type: 2,
          room_id:this.roomId,
          fromType: 3,
        }
      })
    },
    //上传图片
    uploadImg(){
      this.$router.push({
        name: 'AddHouseRelecantPhoto',
        query: {
          // type: 2,
          room_id:this.roomId,
		  setHouse_id:this.setHouse_id,
        }
      })
    },
    //房源特色
    editRoomFeature(){
      this.$router.push({
        name: 'editRoomFeature',
        query: {
          // type: 2,
          room_id:this.roomId,
          setHouse_id:this.setHouse_id,
        }
      })
    },
    //修改报价
    changeQuotation(){
      this.$router.push({
        name: 'changeQuotation',
        query: {
          // type: 2,
          room_id:this.roomId,

        }
      })
    },
    changeCostPrice(){
      this.$router.push({
        name: 'changeCostPrice',
        query: {
          // type: 2,
          room_id:this.roomId,

        }
      })
    } ,
    //房间配置
    roomConfiguration(){
      this.$router.push({
        name: 'roomConfiguration',
        query: {
          // type: 2,
          room_id:this.roomId,
          sethouse_id:this.setHouse_id
        }
      })
    },
     //申请维修
    applyRepair(){
      this.$router.push({
        name: 'applyRepair',
        query: {
          // type: 2,
          room_id:this.roomId,
          sethouse_id:this.setHouse_id
        }
      })
    },
    //标记转租
    houseChangeStatus(){
      this.isChangeStatusShow = true;
    },

    saveChangeStatus(){
      let that = this
      let initData = {}
      initData.user_id = globaluserId()
      initData.roomHouse_id = that.roomId
      changeHouseType(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          // console.log(response.data.data.changType)
          that.changeStatus = response.data.data.changType
          responseUtil.alertMsg(that, '操作成功')
          that.isChangeStatusShow = false;
        })
      })
    },


    datetime(){

    },
    showPopup() {
      this.showaa = !this.showaa;
      // console.log(this.showaa)
    },
    // 提交预约接口
    addUserAppointmentNew() {
      let that = this
      let initData = {};
      initData.user_id = globaluserId(); //用户id
      initData.roomHouseId = this.$route.query.roomId
      // initData.roomHouseId = 126
      initData.isNoManSee = 1
      initData.appointmentTime = new Date(that.dateformat)
      addUserAppointmentNew(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.code==0){
            responseUtil.alertMsg(that,response.data.msg)
            setTimeout(()=>{
              that.leftReturn();   //交互返回
            },2000)

          }else {
            responseUtil.alertMsg(that,response.data.msg)
          }

        })
      })
    },
    leftReturn() {
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          backToAndroid();
        }else if(checkIOS()){
          window.webkit.messageHandlers.closeWindow.postMessage({});
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1)
      }
    },
    // formatDate(date) {
    // 	console.log(date.getMinutes())
    // 	if ((date.getMonth() + 1) < 10) {
    // 		if ((date.getMinutes() ) < 10) {
    // 			return `${date.getFullYear()}/0${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:0${date.getMinutes()}`;
    // 		}
    // 		return `${date.getFullYear()}/0${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    // 	}
    // 	if ((date.getMinutes() ) < 10) {
    // 		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:0${date.getMinutes()}`;
    // 	}else{
    // 		return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    // 	}
    //
    // },
    formatDate(dateTime) {
      // console.log(dateTime.getMinutes())
      let year = `${dateTime.getFullYear()}`;
      let month  = `${dateTime.getMonth() + 1}` ;
      let date = `${dateTime.getDate()}` ;
      let hours = `${dateTime.getHours()}` ; ;
      let minutes = `${dateTime.getMinutes()}` ; ;
      if(month < 10){
        month = `0${dateTime.getMonth() + 1}`
      }
      if(date < 10){
        date = `0${dateTime.getDate()}`
      }
      if(hours < 10){
        hours = `0${dateTime.getHours()}`
      }
      if( minutes < 10){
        minutes = `0${dateTime.getMinutes()}`
      }
      return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;

    },
    onConfirm(date) {
      // console.log(date)
      this.show = false;
      this.dateformat = this.formatDate(date);
      this.showaa = false;
      if (!date){
        this.dateTime=true
      }else{
        this.dateTime=false
      }
    },
    onCancel(){
      this.showaa = false;
    },
    initData() {
      let that = this
      // that.show=true
      let initData = {}
      initData.room_id = that.roomId;
      // initData.room_id = '1';
      initData.user_id = globaluserId();
      roomDetail(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.appointmentData = response.data.data
          that.appointmentData.photo = response.data.data.roomPhotoList.length > 0 ? response.data.data.roomPhotoList[0]
              .roomPhotoPath : that.errorImg
        })
      })
      // console.log(this.show)
    },
    chooseTime() {
      this.show = !this.show
    },
    save() {

    }
  }
}
</script>

<style scoped>
/*保存按钮不可点击样式*/
.saveButton_Disable{
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin:120px 15px 15px 15px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}

  .changeStatusStyle {
    height: 2rem;
  }
  .confirmCancelOrdersText {
    margin-left: 35px;
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: bold
  }

  .cancelOrdersImg {
    float: left;
    margin: 20px 5px 0 15px;
    width: 15px;
    height: 15px;
  }
  .cnacelOrdersText {
    margin-top: 18px;
    font-size: 15px;
    font-weight: bold
  }

.relevvant-detail {
  width: 100%;
  height: 1.2rem;

  font-size: 15px;
  line-height: 1.2rem;
}
.relevvant-detail-border {
  border-bottom: #EFEFEF 1px solid;
  border-radius: 8px;
}


.detail-img {
  height:0.4rem;
  margin-left: 20px;
  padding-top: 0.42rem;
}

.houseRelevantText {
  margin-left: 10px;
  font-family: PingFangSC-Semibold;
  font-weight: bold;
}

.updatePassword {
  background: linear-gradient(to bottom, #FFC274 0px, #FF5D3B 100%) repeat scroll 0% 0%;
  height: 18rem;
}
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin-left:10px;
  margin-right:5px;
}
.content-none {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #c7c7c7;
}
.content-have {
  padding: 3px;
  margin: 0 15px;
  border-radius: 50%;
  background-color: #ff5d3b;
}
.roomMesage{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 5px;
}
.areaDiv{
  display: flex;
  align-items: center;
  margin-top: 7px;
  font-size: 12px;

}
.area {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.devide {
  color: rgba(102, 102, 102, 1);
  font-size: 12px;
  margin: 0px 7px;
  font-family: PingFangSC-Regular;

}

.roomPhoto {
  width: 151.2px;
  height: 100.08px;
  /*padding: 5px;*/
  margin: 5px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.roomPhotoImage{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.submitAppointment {
  width: 345px;
  height: 45px;
  border-radius: 8px;
  margin: 210px 15px 20px 15px;
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Semibold;
  color: white;
  line-height: 45px;
}

.inputDate {
  width: 190px;
  height: 20px;
  /*margin-left: 110px;*/
  outline-style: none;
  border: 0px;
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Regular;
  color: rgba(255, 93, 59, 1);
}

.devide2 {
  width: 1px;
  height: 14px;
  color: rgba(239, 239, 239, 1);
}

.timeText {
  height: 21px;
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  text-align: left;
  font-family: PingFangSC-Semibold;
}




.timePick {
  width: 18.56px;
  height: 19.24px;
  /*margin-left: 15px;*/
}

.timeDiv {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.spanMonth {
  color: rgba(255, 93, 59, 1);
  font-size: 10px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.money {
  width: 70px;
  height: 24px;
  margin-top: 7px;
  color: rgba(255, 93, 59, 1);
  font-size: 16px;
  font-family: Oswald-Regular;

}

.address {
  color: rgba(34, 34, 34, 1);
  font-size: 15px;
  font-family: PingFangSC-Regular;

}
.img_group {
  height: 75px;
  margin: 29px 0px;
}

.setPsd {
  width: 112px;
  height: 40px;
  color: white;
  font-size: 28px;
  text-align: left;
  font-family: PingFangSC-Semibold;
  position: relative;
  font-weight: bold;
  left: 24px;
  bottom: 40px;
}

.oneImg {
  width: 58px;
  height: 66px;
}

.appointmentDetail {
  width: 92%;
  margin: 0 auto;
  height: auto;
  /*display: flex;*/
  /*align-items: flex-start;*/
  /*justify-content: space-between;*/
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);

}
.selectTime{
  width: 10rem;
  height: 3rem;
}


</style>
